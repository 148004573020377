<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="650" persistent>
      <v-card style="height: 100%">
        <v-card-title class="text-h5 grey lighten-2">
          Terms and Privacy Policy of Google Ads
        </v-card-title>
        <div class="font-family-raleway-medium py-4 px-5">
          <div class="font-family-raleway-bold">
            <h2>Some of the main requirements for Google Ads policies include:</h2>
          </div>
          <strong>1.Prohibited content: </strong> Ads cannot promote illegal
          products or services, such as drugs, weapons, or hate speech.
          <br />
          <strong>2.Misleading or deceptive claims: </strong> Ads cannot make
          false or misleading claims about products or services, or use
          deceptive practices such as phishing or malware.
          <br />
          <strong>3.Adult content:</strong> Ads cannot promote adult products or
          services, or depict sexually suggestive or explicit content.
          <br />
          <strong>4.Dangerous or harmful products or services: </strong> Ads
          cannot promote products or services that are dangerous or harmful to
          users, such as tobacco or e-cigarettes.
          <br />
          <strong>5.Personal information: </strong> Ads cannot collect personal
          information from users without their consent.
          <br />
          <strong>6.Intellectual property:</strong> Ads cannot infringe on the
          intellectual property rights of others, such as using copyrighted
          content without permission.
          <br />
          <strong>7.Landing page:</strong> Ads must lead to a landing page that
          is consistent with the ad's content and meets Google's policies.
          <br />
          <strong>8.Alcohol and gambling: </strong> Ads promoting alcohol or
          gambling must comply with the relevant laws and regulations.
          <br />
          <strong>9.Health and pharmaceutical products:</strong> Ads for health
          and pharmaceutical products must comply with the relevant laws and
          regulations.
          <br />
          <strong>10.Sensitive events: </strong> Ads cannot exploit sensitive
          events or tragedies for commercial gain. <br>
          <v-divider class="my-4"/>
          <div class="font-family-raleway-bold">
            <h2>Editorial</h2>
          </div>
          <strong><h3>Style and spelling</h3></strong> <br>
          <div class="ml-2">
            <div>
              <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
              Ads or assets that do not use commonly accepted spelling or grammar
              <br>
              <div class="my-2 ml-3">
                <strong>Examples:</strong> "Flowers here buy" or "Buy flwres here" instead of "Buy flowers here" <br>
              </div>
              <div class="ml-3">
                <strong>Note:</strong> Some trademarked terms, brand names, or product names use nonstandard grammar, spelling, punctuation, or capitalization. If you want to use such names in your ads, you must first request a review and show that the nonstandard terms appear consistently throughout your website or app.
              </div>
            </div>
            <div class="mt-2">
              <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
              Ads or assets that do not use commonly accepted spelling or grammar
              <br>
              <div class="my-2 ml-3">
                <strong class="ml-3">Examples:</strong> "Flowers here buy" or "Buy flwres here" instead of "Buy flowers here" <br>
              </div>
              <div>
                <strong>Note:</strong> Some trademarked terms, brand names, or product names use nonstandard grammar, spelling, punctuation, or capitalization. If you want to use such names in your ads, you must first request a review and show that the nonstandard terms appear consistently throughout your website or app.
              </div>
            </div>
            <div class="mt-2">
              <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
              Ads or assets that are incomprehensible or don’t make sense
              <br>
              <div class="my-2 ml-3">
                <strong>Examples:</strong>Gibberish or overly generic ad text; overly generic or vague promotions; ad text that is cut off or incomplete <br>
              </div>
            </div>

            <div class="mt-2">
              <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
              Ads or assets that exceed character limits for double-width character languages
              <br>
              <div class="ml-3 my-2">
                <strong>Note:</strong> Character limits for text vary depending on whether the text appears in a single-width or double-width language. Double-width languages, such as Chinese, Japanese, and Korean, use double-width characters that take twice as much space as single-width characters. Therefore, character limits in these languages are half that of other languages.
              </div>
            </div>

            <div class="mt-2">
              <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
              Ads or assets that exceed character limits for double-width character languages
              <br>
              <div class="ml-3 my-2">
                <strong>Note:</strong> Character limits for text vary depending on whether the text appears in a single-width or double-width language. Double-width languages, such as Chinese, Japanese, and Korean, use double-width characters that take twice as much space as single-width characters. Therefore, character limits in these languages are half that of other languages.
              </div>
            </div>

            <div class="mt-2">
              <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Ads or assets that are inconsistent with the clear and informational presentation style of the Google Search results
              <br>
              <div class="my-2 ml-3">
                <strong>Examples:</strong>Ads that use bullet points or numbered lists; ads containing a generic call to action (such as "click here") that could apply to any ad <br>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <strong><h3>Punctuation and symbols</h3></strong> <br>
            <div class="ml-2">
              <div>
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Punctuation or symbols that are not used correctly or for their intended purpose
                <div class="my-2 ml-3">
                  <strong>Examples:</strong>Exclamation marks in the ad headline; repeated punctuation or symbols; symbols, numbers, and letters that don't adhere to their true meaning or purpose, such as using "@ home" to mean "at home"; non-standard use of superscripts; non-standard symbols or characters, such as asterisks; bullet points and ellipses; excessive or gimmicky use of numbers, symbols, or punctuation, such as f1owers, fl@wers, Flowers!!!, f*l*o*w*e*r*s, F.L.O.W.E.R.S, use of an exclamation mark or question mark two or more times in ad text
                </div>
                <div class="ml-3">
                  <strong>Note:</strong> Some types of non-standard punctuation and symbols are allowed in certain circumstances. Trademarks, brand names, or product names that use non-standard punctuation or symbols consistently in the ad’s destination can be approved for use in ads. Symbols used in commonly acceptable ways are also allowed, such as using an asterisk for star ratings (5* hotel) or to indicate that legally required conditions apply. To use any of these kinds of punctuation or symbols, you must request a review.              </div>
                <div class="ml-3 mt-2">
                  <strong>Note:</strong> App titles and developer names are subject to this policy. You may need to change the name in the developer console to have your ads approved, or request a review to have the name approved.
                </div>
              </div>
              <div class="mt-3">
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Invalid or unsupported characters
                <br>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <strong><h3>Capitalization</h3></strong> <br>
            <div class="ml-2">
              <div>
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Capitalization that is not used correctly or for its intended purpose
                <div class="my-2 ml-3">
                  <strong class="">Examples:</strong>Excessive or gimmicky use of capitalization, such as the following: FLOWERS, FlOwErS, F.L.O.W.E.R.S
                </div>
                <div>
                  <strong>Note:</strong> In some circumstances, non-standard capitalization is allowed. For coupon codes, common abbreviations (such as "ASAP"), trademarks, brand names, and product names, you can request a review to see if non-standard capitalization can be eligible.</div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <strong><h3>Repetition</h3></strong> <br>
            <div class="ml-2">
              <div>
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Non-standard, gimmicky, or unnecessary repetition of names, words, or phrases
                <div class="my-2 ml-3">
                  <strong>Examples:</strong>Repeating the advertiser name, repeating the product name
                </div>
                <div class="ml-3">
                  <strong>Note:</strong>This policy includes assets repeating text that already appears in ad text.</div>
              </div>
              <div class="mt-3">
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Asset text that repeats words or phrases within the same asset or another asset in the same ad group, campaign, or account
              </div>
            </div>
          </div>

          <div class="mt-3">
            <strong><h3>Unacceptable spacing</h3></strong> <br>
            <div class="ml-2">
              <div>
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Omitting a space or adding extra spaces
                <div class="mt-2 ml-3">
                   <strong>Examples:</strong>"Sale,buy flowers"; "Sale, buy flowers"
                </div>
              </div>
              <div class="mt-3">
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Excessive or gimmicky use of spacing
                <div class="my-2 ml-3">
                  <strong>Examples:</strong>"f l o w e r s, buyflowershere
                </div>
                <div class="ml-3">
                  <strong>Note:</strong>Some trademarked terms, brand names, or product names use non-standard spacing. If you want to use such names in your ads, you must first request a review and show that the non-standard terms appear consistently throughout your website or app.</div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <strong><h3>Phone number in ad text</h3></strong> <br>
            <div class="ml-2">
              <div>
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Entering a phone number in ad text
                <div class="my-2 ml-3">
                  <strong>Examples:</strong>Adding "Call 1-800-123-4567" to the ad’s description
                </div>
                <div class="ml-3">
                  <strong>Note:</strong> If you want to encourage customers to call you, consider using call assets or call-only ads instead of putting the number in your ad text. If your company name is an actual phone number (such as "1-800-EXAMPLE"), request a review to see if you can use it in ad text.</div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <strong><h3>Unidentified business</h3></strong> <br>
            <div class="ml-2">
              <div>
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Ads or destinations that do not name the product, service, or entity they are promoting
                <div class="my-2 ml-3">
                  <strong>Examples:</strong>Not including a product or company name or display URL in the ad; animated ads that do not clearly display identifying information such as a product or company name, logo, or display URL on the final static frame (after the animation has ceased); app ads that do not show the app name and download source in the creative or on the landing page
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <strong><h3>Business name requirements</h3></strong> <br>
            <div class="ml-2">
              <div>
                <span class="font-family-raleway-bold color-red"><v-icon color="red">mdi-close-circle</v-icon></span>
                Ads or destinations that do not name the product, service, or entity they are promoting
                <div class="mt-3 ml-3">
                   <strong>Examples:</strong>Not including a product or company name or display URL in the ad; animated ads that do not clearly display identifying information such as a product or company name, logo, or display URL on the final static frame (after the animation has ceased); app ads that do not show the app name and download source in the creative or on the landing page
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="$emit('closeModal')"
            class="font-family-raleway-bold button px-5 text-capitalize"
          >
            Got it
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "TermsAdsModal",
  props: ["dialog"],
};
</script>

<style scoped></style>
